import React, { useState } from "react"
import Slider from "react-slick"
import { MessageSquare, Video, SearchIcon } from "lucide-react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./AIAgentsSlider.css"
import AiSliderComponent from "../AiSliderComponent"

export default function AIAgentsSlider() {
  const [currentSlide, setCurrentSlide] = useState(0)

  const items = [
    {
      number: "01",
      title: "Enhance Operational Efficiency",
      icon: (
        <i
          className="icon-ai-agent-development-company----enhance-operational-efficiency"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "AI agents handle time-consuming tasks like data entry, scheduling, and basic customer queries, allowing your team to focus on higher-value activities. This leads to greater productivity and cost savings.",
    },
    {
      number: "02",
      title: "Automate Complex Workflows",
      icon: (
        <i
          className="icon-ai-agent-development-company---automate-complex-workflows"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "By incorporating machine learning and automation, AI agents can streamline multi-step processes, handling tasks that were once cumbersome and manual. This leads to smoother workflows and faster turnaround times.",
    },
    {
      number: "03",
      title: "Offer Scalable Solutions",
      icon: (
        <i
          className="icon-ai-agent-development-company---offer-scalable-solutions"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "As your business grows, AI agents can easily scale with you. They can manage increasing workloads without compromising performance, ensuring that you're always ready to meet new challenges.",
    },
    {
      number: "04",
      title: "Provide 24/7 Customer Engagement",
      icon: (
        <i
          className="icon-ai-agent-development-company---provide-24-7-customer-engagement"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "AI agents can engage with customers round the clock, offering support, answering questions, and providing recommendations without the need for human intervention. This continuous availability improves customer satisfaction and retention.",
    },
    {
      number: "05",
      title: "Personalize User Experiences",
      icon: (
        <i
          className="icon-ai-agent-development-company---personalize-user-experiences"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "AI agents can analyze customer behavior and preferences to deliver personalized recommendations and solutions. By tailoring experiences to individual users, you can increase conversion rates and customer loyalty.",
    },
    {
      number: "06",
      title: "Optimize Decision-Making with Data",
      icon: (
        <i
          className="icon-ai-agent-development-company---optimize-decision-making-with-data"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "With AI-powered insights, businesses can make more informed decisions. AI agents process vast amounts of data to deliver actionable insights in real-time, helping you stay competitive and proactive in addressing market changes.",
    },
    {
      number: "07",
      title: "Reduce Human Error",
      icon: (
        <i
          className="icon-ai-agent-development-company--reduce-human-error"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "AI agents are designed to perform with a high degree of accuracy. By automating tasks that humans may sometimes overlook or misinterpret, you reduce the risk of errors, improving overall business quality and customer trust.",
    },
    {
      number: "08",
      title: "Foster Innovation and Adaptability",
      icon: (
        <i
          className="icon-ai-agent-development-company---custom-ai-agent-solutions"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "AI agents continuously learn and adapt, enabling businesses to stay on the cutting edge of technological advancements. As they evolve over time, they unlock new opportunities for growth, innovation, and competitive advantage.",
    },
    {
      number: "09",
      title: "Task Automation and Workflow Optimization",
      icon: (
        <i
          className="icon-ai-agent-development-company----task-automation-and-workflow-optimization"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Implement AI-driven task automation to streamline repetitive processes and optimize complex workflows, freeing up valuable resources and ensuring consistent execution of business operations.",
    },
    {
      number: "10",
      title: "Real-Time Analytics and Reporting",
      icon: (
        <i
          className="icon-ai-agent-development-company----real-time-analytics-and-reporting"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Leverage AI agents to process and analyze data in real-time, providing actionable insights and automated reporting that helps your business respond quickly to changing market conditions and opportunities.",
    },
    {
      number: "11",
      title: "Security and Compliance",
      icon: (
        <i
          className="icon-ai-agent-development-company---security-and-compliance"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Enhance your security posture with AI agents that continuously monitor for threats, ensure regulatory compliance, and protect sensitive data through advanced pattern recognition and anomaly detection.",
    },
  ]

  const sliderRef = React.useRef(null)

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    beforeChange: (current, next) => setCurrentSlide(next),
  }

  return (
    <div className="relative w-full bg-[#F0F4FF] overflow-hidden">
      <div
        className="absolute inset-0 bg-cover bg-center"
        // style={{
        //   backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Our-AI-Approach.webp")`,
        // }}
      />
      <div className="relative max-w-screen-2xl mx-auto px-4 py-16">
        <div className="text-center mb-11 ">
          <h1
            className="text-2xl md:text-5xl font-bold text-[rgba(36, 38, 43, 1)] px-6 md:px-10 py-1 md:py-2 inline-block"
            style={{
              borderRadius: "40px",
              border: "6px solid rgba(255, 249, 249, 0.08)",
              background: "rgba(255, 255, 255, 0.16)",
            }}
          >
            Why AI Agents Are Essential for Your Business Growth
          </h1>
        </div>

        <p className="text-center text-lg font-medium mb-12 max-w-3xl mx-auto text-[rgba(36, 38, 43, 1)]">
          AI agents are more than just tools—they’re intelligent solutions that
          can revolutionize how your business functions. By automating
          repetitive tasks, improving customer engagement, and enabling
          data-driven decision-making, AI agents are a powerful asset that can
          help businesses thrive in an increasingly digital world.
        </p>
        <p className="text-center text-lg font-medium mb-12 max-w-3xl mx-auto text-[rgba(36, 38, 43, 1)]">
          Here’s how adopting AI agents can drive success for your organization:
        </p>

        <div className="relative md:px-8 md:py-12">
          <Slider ref={sliderRef} {...settings} className="gap-slider">
            {items.map((item, index) => (
              <AiSliderComponent
                key={index}
                number={item.number}
                title={item.title}
                icon={item.icon}
                description={item.description}
              />
            ))}
          </Slider>

          <div className="flex justify-center items-center gap-4 mt-8">
            <button
              onClick={() => sliderRef.current?.slickPrev()}
              className="w-12 h-12 rounded-full border  bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
              style={{ border: "1px solid #335FFF" }}
            >
              <i
                className={`icon-slider-left-arrow  flex justify-center py-3 mx-0 active:text-blue-500`}
                style={{ fontSize: "20px", color: "#335FFF" }}
              />
            </button>

            <span className=" text-lg font-bold" style={{ color: "#335FFF" }}>
              {currentSlide + 1} /{" "}
              <span
                className=" text-lg font-bold opacity-50 "
                style={{ color: "#335FFF" }}
              >
                {items.length}
              </span>
            </span>
            <button
              type="button"
              onClick={() => sliderRef.current?.slickNext()}
              className="w-12 h-12 rounded-full border border-[#335FFF] bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
              style={{ border: "1px solid #335FFF" }}
            >
              <i
                className={`icon-slider-right-arrow  flex justify-center py-3 mx-0 active:text-blue-500`}
                style={{ fontSize: "20px", color: "#335FFF" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
